body {
    margin: 0;
    padding: 0;
    background: #ffffff;
    font-family: Arial, sans-serif;
  }
  
  .form-container {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: 1rem;
  }
  
  .carnet-form {
    background: white;
    padding: 2rem;
    border-radius: 12px;
    width: 100%;
    max-width: 400px;
    display: flex;
    flex-direction: column;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  /* Logo */
  .carnet-form img.logo {
    width: 150px;
    margin: 0 auto 1.5rem auto;
    display: block;
  }
  
  /* Inputs */
  .carnet-form input {
    margin-bottom: 1rem;
    padding: 0.75rem;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 1rem;
  }
  
  .carnet-form input[type="file"] {
    padding: 0.4rem;
  }
  
  /* Botón */
  .carnet-form button {
    padding: 0.75rem;
    background: #000;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 1rem;
    transition: background 0.3s ease;
  }
  
  .carnet-form button:hover {
    background: #444;
  }
  
  /* 🌐 Responsive */
  @media (max-width: 768px) {
    .carnet-form {
      padding: 1.5rem;
    }
    .carnet-form img.logo {
      width: 120px;
      margin-bottom: 1.2rem;
    }
    .carnet-form input, .carnet-form button {
      font-size: 0.95rem;
    }
  }
  
  @media (max-width: 480px) {
    .carnet-form {
      padding: 1rem;
    }
    .carnet-form img.logo {
      width: 100px;
      margin-bottom: 1rem;
    }
    .carnet-form input, .carnet-form button {
      font-size: 0.9rem;
      padding: 0.65rem;
    }
  }
  